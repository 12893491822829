export const environment = {
  production: false,
  baseUrl: "https://uat2-api-expo.appgrowthcompany.com",
  socketUrl: "https://uat2-order-expo.appgrowthcompany.com",
  greatBaseUrl: "https://prod.webdevelopmentsolution.net:3050",
  appId: "gtobnkmzymnol9499avlcjet",
  common: "common",
  order: "order",
  iv: "D904363DB8DACEB8",
  key: "MKOIJNQASDFVRGHNBHUCFTXDRESZWA",
  firebase: {
    apiKey: "AIzaSyB-CaOsMzrqPSpQuuswcBYNlx1KchhbrSI",
    authDomain: "expoeats.firebaseapp.com",
    projectId: "expoeats",
    storageBucket: "expoeats.appspot.com",
    messagingSenderId: "851186867177",
    appId: "1:851186867177:web:69bd14594a592aa6fb8978",
    vapidKey:
      "BJwlGZkalL28R87D9T4D9A_o8RjvUHeQHePkUWjFdQDc6qPqUnGPjz_NPBzA2fAeRlyEBRb77FFAwVEdEroWhMU",
  },
  path: "/firebase-messaging-sw.js",
  scope: "/",
  domain: "https://expoeatsmerchant.appgrowthcompany.com/#"
};
