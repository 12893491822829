<div class="login_formss losgin_formss">
  <div class="text-left">
    <div class="otpscbna"><img src="" /></div>
    <div class="sub_main_heading">
      <h2>Select from the options below to verify your account.</h2>
      <p>Security and safety are very important for us!</p>
    </div>
    <div class="slecttype">
      <mat-radio-group [(ngModel)]="selectedType" aria-label="Select an option">
        <mat-radio-button value="1">{{ data?.email }}</mat-radio-button>
        <mat-radio-button value="0">{{ data?.phone }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <button
      class="btn btn-primary common-btn"
      type="submit"
      [disabled]="!selectedType"
      (click)="continue()"
    >
      continue
    </button>
  </div>
</div>
