import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { Observable, Subject } from "rxjs";
import socketIo from "socket.io-client";
@Injectable({
  providedIn: "root",
})
export class SocketService {
  socket: any;
  websiteConnect: Subject<boolean> = new Subject<boolean>();
  isWebsiteConnect = this.websiteConnect.asObservable();

  constructor(private comm: CommonService) {}

  initSocket() {
    if (localStorage.merchantToken !== undefined) {
      this.socket = socketIo.connect(this.comm.socketUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        query: {
          token: localStorage.merchantToken,
        },
      });
      // Add Admin
      let payload = {
        token: localStorage.getItem("merchantToken"),
      };
      this.emitAction("addMerchant", payload);
    }
  }

  emitAction(action: any, payload: any): void {
    payload["appId"] = this.comm.getGreatSettings()["appId"];
    this.socket.emit(action, payload);
  }

  public onEvent(event: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on(event, (data) => observer.next(data));
    });
  }

  disconnect() {
    this.socket.disconnect();
  }
}
