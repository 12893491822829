import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
export function encryptData(data, isHelper = false): any {
  const iv = CryptoJS.enc.Base64.parse(environment.iv); //giving empty initialization vector
  const key = CryptoJS.SHA256(environment.key); //hashing the key using SHA256
  let encryptedString: any;

  const setEncryptedString = (payload) => {
    return CryptoJS.AES.encrypt(payload, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  };

  data = typeof data == "string" ? data.slice() : data;
  encryptedString = setEncryptedString(data);

  if (isHelper) {
    return encryptedString.toString();
  } else {
    return {
      hash: encryptedString.toString(),
    };
  }
}
