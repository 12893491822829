<h2 mat-dialog-title>Estimated preparation time</h2>
<div class="dialog">
  <input
    type="text"
    value="30"
    [(ngModel)]="time"
    class="form-control"
    placeholder="Enter time in minutes"
    appNumberOnly
  />
</div>

<div class="botton_right_buttons">
  <mat-dialog-actions>
    <button class="btn btn-primary common-btn mr-2" (click)="onSubmit()">
      Add
    </button>
    <button class="btn btn-danger common-btn" (click)="close()">Cancel</button>
  </mat-dialog-actions>
</div>
