import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { coreUrlList } from 'src/app/core/urlList/coreUrlList';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  type:string;
  crmData: any;

  constructor(private route:ActivatedRoute,private _apiService:HttpService,private coreUrl:coreUrlList) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(res=>{
      if(res){
        this.type=res["type"];
        this.getCrm();
      }
    })
  }
  getCrm(){
    this._apiService.get(this.coreUrl.getCrm).subscribe(res=>{
      if(res["response"]["success"]){
        this.crmData = res["data"]
      }
    })
  }

}
