<div class="wrap-sec">
  <div class="container">

  <div class="user_title">
    <div class="user_title_inner sales_fdx">
      <h6>CMS</h6>
    </div>
  </div>
  <div *ngIf="crmData">
    <span class="fulfl"
       [innerHTML]="type === 'terms'?crmData['termsAndConditions']:crmData['merchantPrivacyPolicy']">
      
  </span>
    
  </div>
</div>

</div>
