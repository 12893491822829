import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
@Injectable()
export class coreUrlList {
  comm = `/${environment.common}/v3`;
  onDemand = `/${environment.order}/v3`;
  // File Upload
  uploadFile: string = `${this.comm}/upload/upload`;

  //  App Setting
  appSetting: string = `${this.comm}/admin/appSetting`;
  getAppSetting: string = `${this.comm}/admin/getAppSetting`;

  // Auth
  login: string = `${this.comm}/store/login`;
  forgotPassword: string = `${this.comm}/store/forgotPassword`;
  forgotPasresendOtpsword: string = `${this.comm}/store/resendOtp`;
  setPassword: string = `${this.comm}/store/setPassword`;
  verifyOtp: string = `${this.comm}/store/verifyOtp`;
  uploadDocument: string = `${this.comm}/store/document`;
  getDocument: string = `${this.onDemand}/store/getDocumentList`;
  changePassword: string = `${this.comm}/admin/panel/changepass`;
  customerSetting: string = `${this.comm}/admin/getCustomerSetting`;
  updateUploadedDocuments: string = `${this.comm}/store/updateUploadedDocuments`;

  // Sign Up
  signup: string = `${this.comm}/store/signup`;
  resendOtp: string = `${this.comm}/store/resendOtp`;
  storeProfileSetup: string = `${this.comm}/store/storeProfileSetup`;

  // Store
  store: string = `${this.onDemand}/admin/store`;
  storeById: string = `${this.onDemand}/admin/store/getStoreById`;
  getRevenueStore: string = `${this.onDemand}/admin/store/revenue/store`;
  getStoreRevenueById: string = `${this.comm}/admin/store/getAllMerchantTotalCommission`;
  getPendingOrderCount: string = `${this.onDemand}/admin/pendingOrderCount`;
  getStorePaymentHistory: string = `${this.onDemand}/admin/payHistory`;
  addEditTax: string = `${this.comm}/admin/tax`;
  addEditGetTags: string = `${this.onDemand}/admin/tags`;
  getMerchantWiseCommission: string = `${this.onDemand}/admin/payoutList`;
  holidaySlot: string = `${this.onDemand}/admin/store/holidayTimingService`;
  storeServiceSlot: string = `${this.onDemand}/store/storeServiceSlot`;
  createTableBooking: string = `${this.onDemand}/store/createTableBooking`;

  // Time Slots
  addTimeSlot: string = `${this.onDemand}/admin/addTimeSlot`;
  getTimeSlots: string = `${this.onDemand}/admin/getTimeSlot`;

  // Outlet
  outlet: string = `${this.onDemand}/admin/store/outlet`;

  // Zone
  getZones: string = `${this.onDemand}/zone/getZones`;
  getGeofence: string = `${this.onDemand}/zone/geofence`;

  // Products
  products: string = `${this.onDemand}/admin/store/product`;
  variant: string = `${this.onDemand}/admin/store/variant`;
  deleteSelectedProducts: string = `${this.onDemand}/admin/store/deleteSelectedProduct`;
  deleteAllMerchantProducts: string = `${this.onDemand}/admin/store/deleteAllProduct`;
  importProductsForStore: string = `${this.onDemand}/admin/store/bulkUploadProduct`;
  exportProducts: string = `${this.onDemand}/admin/store/getAllProductExport`;
  getInventoryProducts: string = `${this.onDemand}/admin/store/getStoreInventory`;
  updateCloneProducts: string = `${this.onDemand}/admin/store/updatecloneProducts`;
  getPrototypeProducts: string = `${this.onDemand}/admin/store/getprototypeProducts`;
  addVariantToInventory: string = `${this.onDemand}/admin/store/cloneProduct`;
  getProductById: string = `${this.onDemand}/admin/store/itembyid`;
  addgetTable: string = `${this.onDemand}/admin/store/table`;
  getAllTableBooking: string = `${this.onDemand}/admin/store/tableBooking`;
  changeArrangingFeaturedProduct: string = `${this.onDemand}/admin/store/changeArrangingFeaturedProduct`;

  // Brands
  brand: string = `${this.onDemand}/admin/store/brand`;

  // Employee
  addEmployee: string = `${this.onDemand}/store/employee/signup`;
  updateEmployee: string = `${this.onDemand}/store/employee/updateEmployee`;
  getEmployees: string = `${this.onDemand}/store/allEmployee`;
  getAllEmployees: string = `${this.onDemand}/admin/store/allEmployee`;
  deleteEmployee: string = `${this.onDemand}/store/employee/delete`;
  getEmployeeyId: string = `${this.onDemand}/store/employee/employeeByid`;
  assisnOrderToEmployee: string = `${this.onDemand}/store/assignOrder`;

  // store:
  storeType: string = `${this.onDemand}/admin/store/storeType`;

  // Category
  getStoreSubCatAndCat: string = `${this.onDemand}/admin/store/getStoreSubCatAndCat`;
  getCatWithProdThroughStoreId: string = `${this.onDemand}/admin/store/getcategories`;
  getAllBrandsWithProdThroughStoreId: string = `${this.onDemand}/admin/store/getBrandsByStore`;
  getCatWithProdThroughType: string = `${this.onDemand}/admin/store/getcategoriesbystoretype`;
  getAllBrandsWithProdThroughType: string = `${this.onDemand}/admin/store/getBrandsByStoreType`;
  getSubCatWithProdThroughType: string = `${this.onDemand}/admin/store/getSubCategoriesByStoreType`;
  getAllStoreCategories: string = `${this.onDemand}/admin/store/category`;

  // Notification
  getNotification: string = `${this.onDemand}/store/notification`;
  getUserRating: string = `${this.onDemand}/store/rating`;
  productRating: string = `${this.onDemand}/store/productRating`;

  // Subscribe To Notification
  subscribeToNotification: string = `${this.onDemand}/store/subscribe`;

  // Order
  getStoreOrders: string = `${this.onDemand}/admin/store/orders/all`;
  getEmployeeOrders: string = `${this.onDemand}/admin/store/employee/orders`;
  getStoreOrderDetail: string = `${this.onDemand}/admin/store/order`;
  getRating: string = `${this.onDemand}/store/rating`;
  getAllOrdersCSV: string = `${this.onDemand}/admin/getAllOrdersCSV`;
  createOrderByAdmin: string = `${this.onDemand}/admin/createOrderByAdmin`;
  getOrderInvoice: string = `${this.onDemand}/admin/store/pdfOrder`;

  //Cms
  getCrm: string = `${this.comm}/admin/getCrm`;

  //Addons
  AddOns: string = `${this.onDemand}/admin/addOns`;
  getAddOn: string = `${this.onDemand}/admin/getAddOn`;
  deleteAddOns: string = `${this.onDemand}/admin/deleteAddOns`;
  getToppingItems: string = `${this.onDemand}/admin/getToppingItems`;
  updateAddOnsItem: string = `${this.onDemand}/admin/updateAddOnsItem`;
}
