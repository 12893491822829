import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appNumberDotOnly]",
})
export class NumberDotOnlyDirective {
  constructor(private _el: ElementRef) {}

  @HostListener("input", ["$event"]) onInputChange(event: any) {
    const inputValue: string = this._el.nativeElement.value;

    // Remove any characters that are not digits or the first decimal point
    const sanitizedValue: string = inputValue.replace(/[^\d.]/g, "");

    // Split the value into integer and decimal parts
    const parts: string[] = sanitizedValue.split(".");

    // Ensure there is at most one decimal point and two digits after the decimal point
    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, 2); // Keep only two decimal digits
      parts[0] = parts[0] + "." + parts[1]; // Recombine integer and decimal parts
    }

    // Assign the sanitized value back to the input field
    this._el.nativeElement.value = parts[0];

    // If the input value was changed, stop event propagation
    if (inputValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
