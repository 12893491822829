import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import { InitialService } from "./initial.service";
import Swal from "sweetalert2";
import { HttpService } from "./http.service";
import { coreUrlList } from "../urlList/coreUrlList";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  orderStatus: string = "pending";
  title: any;
  emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  dropSetting = {
    enableCheckAll: false,
    singleSelection: false,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  singleDropSetting = {
    enableCheckAll: false,
    singleSelection: true,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  confirmToast(text) {
    return Swal.fire({
      title: "Are you sure?",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }
  baseUrl;
  imageUrl;
  baseUrl2: any;

  // Socket Url
  socketUrl = environment.socketUrl;

  public userToggel = new BehaviorSubject<any>({});
  public handlestatusChange = new BehaviorSubject<any>({});
  public pendingOrderCount$ = new Subject<any>();
  public fcmToken = new Subject<any>();
  constructor(
    private spinner: NgxSpinnerService,
    private toaster: ToastrManager,
    private http: HttpClient,
    private initial: InitialService
  ) {
    let data = this.initial.getBaseUrl();
    this.baseUrl = data.baseUrl;
    this.imageUrl = data.imageUrl;
  }

  showSpinner() {
    this.spinner.show();
  }
  hideSpinner() {
    this.spinner.hide();
  }
  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map((response) => response));
  }
  successToast(message) {
    this.toaster.successToastr(message, "", {
      maxShown: 1,
    });
  }
  errorToast(message) {
    this.toaster.errorToastr(message);
  }
  toggleStatus(val) {
    this.userToggel.next(val);
  }
  handleStatus(val) {
    this.handlestatusChange.next(val);
  }
  getGreatSettings() {
    return JSON.parse(localStorage.getItem("greatSettings"));
  }
  getVerifyOtpData() {
    return JSON.parse(localStorage.getItem("verifyOtpData"));
  }
  getAppSettings() {
    return JSON.parse(localStorage.getItem("appSettings"));
  }
  getFromLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }
  getModuleFromModuleKey(moduleKey) {
    let moduleList = this.getGreatSettings()["appType"];
    let module = moduleList.find((element) => element.moduleKey == moduleKey);
    return module;
  }

  deleteEmptyKeys(value) {
    for (const key in value) {
      if (
        value[key] === "" ||
        value[key] === null ||
        value[key] === undefined
      ) {
        delete value[key];
      }
    }
    return value;
  }

  changeTimeInpToMin(startTime, endTime) {
    startTime =
      startTime !== ""
        ? Number(startTime.split(":")[0]) * 60 + Number(startTime.split(":")[1])
        : 0;
    endTime =
      endTime !== ""
        ? Number(endTime.split(":")[0]) * 60 + Number(endTime.split(":")[1])
        : 0;
    return { startTime: startTime, endTime: endTime };
  }

  convertMinToStr(startTime, endTime) {
    if (startTime > 0 || endTime > 0) {
      let newStartTime, newEndTime;

      newStartTime = String(Math.floor(startTime / 60)) + ":";

      newEndTime = String(Math.floor(endTime / 60)) + ":";
      if (startTime - Number(Math.floor(startTime / 60)) * 60 < 10) {
        startTime =
          newStartTime +
          `0${startTime - Number(Math.floor(startTime / 60)) * 60}`;
      } else {
        startTime =
          newStartTime +
          `${startTime - Number(Math.floor(startTime / 60)) * 60}`;
      }
      if (endTime - Number(Math.floor(endTime / 60)) * 60 < 10) {
        endTime =
          newEndTime + `0${endTime - Number(Math.floor(endTime / 60)) * 60}`;
      } else {
        endTime =
          newEndTime + `${endTime - Number(Math.floor(endTime / 60)) * 60}`;
      }
    } else {
      if (startTime === 0) {
        startTime = "00:00";
      }
      if (endTime === 0) {
        endTime = "00:00";
      }
    }
    return { startTime: startTime, endTime: endTime };
  }

  setTitleByModuleName(moduleName, type) {
    switch (moduleName) {
      case "foodDeliveryApp": {
        this.title = "Cuisine " + type;
        if (type === "store") {
          this.title = "Restaurants";
        }
        if (type === "Product") {
          this.title = "Products";
        }
        break;
      }
      case "homeservice": {
        this.title = "Provider " + type;
        if (type === "store") {
          this.title = "Provider";
        }
        if (type === "Product") {
          this.title = "Service";
        }
        break;
      }
      case "pharmacy": {
        this.title = "Pharmacy " + type;
        if (type === "store") {
          this.title = "Pharmacy";
        }
        if (type === "Product") {
          this.title = "Medicine";
        }
        break;
      }
      default: {
        this.title = "Merchant " + type;
        if (type === "store") {
          this.title = "Merchant";
        }
        if (type === "Product") {
          this.title = "Products";
        }
        break;
      }
    }
    return this.title;
  }
  deleteEmptyKeysFromObject(obj) {
    for (let key in obj) {
      if (obj[key] === null || obj[key] == "" || obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }
  iv = CryptoJS.enc.Base64.parse("D904363DB8DACEB8"); //giving empty initialization vector
  key = CryptoJS.SHA256("MKOIJNQASDFVRGHNBHUCFTXDRESZWA"); //hashing the key using SHA256
  encryptedString: any;
  encryptData(data) {
    if (typeof data == "string") {
      data = data.slice();
      this.encryptedString = CryptoJS.AES.encrypt(data, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
    } else {
      this.encryptedString = CryptoJS.AES.encrypt(data, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
    }
    // var decrypteddata = this.decryptData(
    //   this.encryptedString.toString(),
    //   this.iv,
    //   this.key
    // );
    // console.log(decrypteddata, 'decrypted data'); //genrated decryption string:  Example1
    return {
      hash: this.encryptedString.toString(),
      // sek: this.generateRandomString(12),
      // appKey: this.generateRandomString(12),
    };
  }
  decryptData(encrypted, iv, key) {
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  successDialog(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      timer: 1000,
      icon: "success",
      showConfirmButton: false,
    }).then();
  }
  errorDialog(title) {
    return Swal.fire({
      title: title,
      timer: 1500,
      icon: "error",
      showConfirmButton: false,
    }).then();
  }
}
