import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrManager } from "ng6-toastr-notifications";

import { CommonService } from "src/app/core/services/common.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/coreUrlList";

@Component({
  selector: "app-import-products-modal",
  templateUrl: "./import-products-modal.component.html",
  styleUrls: ["./import-products-modal.component.scss"],
})
export class ImportProductsModalComponent implements OnInit {
  fileName: any;
  uploaded: boolean;
  image: any;
  constructor(
    private dialog: MatDialogRef<ImportProductsModalComponent>,
    public comm: CommonService,
    public toastr: ToastrManager,
    public api: HttpService,
    public coreUrlList: coreUrlList
  ) {}
  apiCall: boolean = false;
  File;
  submitted: boolean = false;
  errors: boolean = false;
  progress;
  storeId;
  ngOnInit() {}

  async fileHandler(event) {
    this.File = event.target.files[0];
    this.fileName = this.File["name"];
    this.errors = this.File ? true : false;
    this.uploaded = true;
    this.image = this.File["name"];
  }

  onSubmit() {
    this.submitted = true;
    if (this.File) {
      this.errors = false;
      if (this.submitted) {
        let formData = new FormData();
        formData.append("image", this.File);
        // formData.append("moduleKey", this.moduleKey);
        this.api
          .post(this.coreUrlList.importProductsForStore, formData)
          .subscribe(
            (res) => {
              if (res["response"]["success"]) {
                this.comm.successToast(res["response"]["message"]);
                this.dialog.close("yes");
              } else {
                this.dialog.close("no");
              }
            },
            (err) => {
              this.getErrorSheet(err["error"]["data"]);
              this.dialog.close("no");
            }
          );
      }
    } else {
      this.errors = true;
    }
  }

  getErrorSheet(url) {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    link.setAttribute("download", `Products.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  close() {
    this.dialog.close({ res: "no" });
  }
}
