import { Input } from "@angular/core";
import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appNoSpaceAtFirst]",
})
export class appNoSpaceAtFirst {
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef) {}
  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.el.nativeElement.selectionStart === 0 && event.key === " ") {
      event.preventDefault();
    }
  }
}
