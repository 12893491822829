import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";
import { CommonService } from "src/app/core/services/common.service";
import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/coreUrlList";
import { MapsAPILoader } from "@agm/core";
declare var google: any;
@Component({
  selector: "app-store-outletmodal",
  templateUrl: "./storeOutletModal.component.html",
  styleUrls: ["./storeOutletModal.component.scss"],
})
export class StoreOutletmodalComponent implements OnInit {
  map: any;
  outletForm: FormGroup;
  item: any;
  storeId;
  updateAddrssStatus: boolean = false;
  updateAddress: any;
  latitude: number;
  longitude: number;
  zoom: number;
  addressLocation: any;
  drawingManager: any;
  selectedArea = 0;
  pointList: { lat: number; lng: number }[] = [];
  geoCoder: any;
  outlet: { address: any; latitude: any; longitude: any };
  name = "Angular";
  bounds: any;
  polygonPts: any[] = [];
  ptsList: string;
  geofence;
  polygonArr;
  areaType: any = "fixed_area";
  deliveryAreaGeofence: any;
  geoLatLong: any;
  constructor(
    private dialog: MatDialogRef<StoreOutletmodalComponent>,
    private formBuilder: FormBuilder,
    public comm: CommonService,
    public toastr: ToastrManager,
    public api: HttpService,
    private mapsAPILoader: MapsAPILoader,
    public coreUrlList: coreUrlList
  ) {}

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.createForm();
    this.setGeofence();
    if (this.item) {
      this.setValues();
    } else {
      this.setCurrentLocation();
    }
  }

  createForm() {
    this.outletForm = this.formBuilder.group({
      address: new FormControl("", Validators.compose([Validators.required])),
      storeId: new FormControl("", Validators.compose([Validators.required])),
      latitude: new FormControl("", Validators.compose([Validators.required])),
      longitude: new FormControl("", Validators.compose([Validators.required])),
      deliveryAreaType: new FormControl(
        "fixed_area",
        Validators.compose([Validators.required])
      ),
      deliveryArea: new FormControl(
        0,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[1-9]+[0-9]*$/),
        ])
      ),
    });
  }

  setGeofence() {
    this.geofence = JSON.parse(localStorage.getItem("geofence"));
    if (
      this.geofence &&
      this.geofence.geoLongLat &&
      this.geofence.geoLongLat.coordinates &&
      this.geofence.geoLongLat.coordinates[0] &&
      this.geofence.geoLongLat.coordinates[0].length
    ) {
      this.polygonArr = this.geofence.geoLongLat.coordinates[0];
    }
  }

  mapReady(event) {
    let arr = [];
    if (this.polygonArr) {
      this.polygonArr.filter((item) => {
        arr.push(new google.maps.LatLng(item[1], item[0]));
      });
      this.polygonPts = [arr];
      this.ptsList = JSON.stringify(this.polygonPts);
    }
  }

  mapClick(event) {
    // For some reason the push function does not work
    // but using the spread operator does.
    let ll = new google.maps.LatLng(event.coords.lat, event.coords.lng);

    // this will fail
    this.polygonPts.push(ll);

    //uncomment this to get it to work.
    //this.polygonPts = [...this.polygonPts, ll];
    this.ptsList = JSON.stringify(this.polygonPts);
  }

  setCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 11;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnds(event: MouseEvent) {
    this.latitude = event["coords"].lat;
    this.longitude = event["coords"].lng;
    this.getAddress(this.latitude, this.longitude);
    this.updateAddrssStatus = true;
  }

  onMapReady(map: any) {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event: {
        type: string;
        overlay: { getPaths: () => any; drag: any; getPath: () => any };
      }) => {
        if (event.type === "polygon") {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(paths.getAt(p), "set_at", () => {
              if (!event.overlay.drag) {
                self.updatePointList(event.overlay.getPath());
              }
            });
            google.maps.event.addListener(paths.getAt(p), "insert_at", () => {
              self.updatePointList(event.overlay.getPath());
            });
            google.maps.event.addListener(paths.getAt(p), "remove_at", () => {
              self.updatePointList(event.overlay.getPath());
            });
          }
          self.updatePointList(event.overlay.getPath());
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
      }
    );
  }

  updatePointList(path: {
    getLength: () => any;
    getAt: (arg0: number) => {
      (): any;
      new (): any;
      toJSON: { (): { lat: number; lng: number }; new (): any };
    };
  }) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(path.getAt(i).toJSON());
    }
    this.geoLatLong = this.setPointer(this.pointList);
    this.selectedArea = google.maps.geometry.spherical.computeArea(path);
  }
  setPointer(pointList) {
    let point,
      point1 = [];
    pointList.map((item) => {
      point = [item.lng, item.lat];
      point1.push(point);
    });
    let point2 = point1[0];
    let data = point1;
    data.push(point2);
    return data;
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        this.addressLocation = results[0].formatted_address;
        this.outletForm.patchValue({
          address: this.addressLocation,
          latitude: latitude,
          longitude: longitude,
        });
      }
    );
  }

  setValues() {
    let arr = [];
    if (this.item) {
      this.outletForm.patchValue({
        address: this.item.address,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        storeId: this.item.storeId,
        deliveryAreaType: this.item.deliveryAreaType,
        deliveryArea: this.item.deliveryArea,
      });
      this.areaType = this.item.deliveryAreaType;
      this.latitude = this.item.latitude;
      this.longitude = this.item.longitude;
      this.updateAddress = this.outletForm.controls["address"].value;
      if (this.areaType == "geo_fence") {
        this.geoLatLong = this.item.geoLongLat.coordinates[0];
        this.item.geoLongLat.coordinates.filter((item) => {
          item.filter((data) => {
            let keys = {};
            keys["lat"] = data[1];
            keys["lng"] = data[0];
            arr.push(keys);
          });
        });

        this.deliveryAreaGeofence = arr;
      }
      this.changeAreaType(this.areaType);
    }
  }

  changeAreaType(type) {
    this.areaType = type;
    if (type == "geo_fence") {
      this.outletForm.controls["deliveryArea"].setValidators(null);
      this.outletForm.controls["deliveryArea"].setErrors(null);
      this.outletForm.get("deliveryArea").updateValueAndValidity();
    } else {
      this.outletForm.controls["deliveryArea"].setValidators([
        Validators.required,
      ]);
      this.outletForm.get("deliveryArea").updateValueAndValidity();
    }
  }

  prepareAndValidateData() {
    if (this.outletForm.valid) {
      let data = this.outletForm.value;
      data.deliveryArea = Number(data.deliveryArea);
      if (this.areaType == "fixed_area") {
        return data;
      } else if (this.areaType == "geo_fence") {
        if (!this.item) {
          if (this.pointList.length < 3) {
            this.comm.errorToast("Please add geofence");
            return false;
          } else {
            data.geoLongLat = [];
            data.geoLongLat.push(this.geoLatLong);
            return data;
          }
        } else {
          if (this.pointList) {
            data.geoLongLat = [];
            data.geoLongLat.push(this.geoLatLong);

            return data;
          } else {
            data.geoLongLat = {};
            data.geoLongLat.coordinates = this.item.geoLongLat.coordinates;
            return data;
          }
        }
      }
    } else {
      this.outletForm.markAllAsTouched();
      this.comm.errorToast("Please fill complete form");
      return false;
    }
  }

  onSubmit() {
    this.outletForm.controls["storeId"].setValue(this.storeId);
    let data = this.prepareAndValidateData();
    console.log(data);

    if (data) {
      this.api
        .post(this.coreUrlList.outlet, this.outletForm.value)
        .subscribe((res) => {
          if (res["response"]["success"]) {
            this.comm.successToast(res["response"]["message"]);
            this.dialog.close("yes");
          } else {
            this.dialog.close("no");
          }
        });
    } else if (this.outletForm["controls"].address.value) {
      this.toastr.errorToastr(
        "Please select a valid address from suggestions."
      );
    }
  }

  onUpdate() {
    this.outletForm.controls["storeId"].setValue(this.item.storeId);
    if (this.outletForm.valid) {
      if (this.outletForm["controls"].address.value === this.updateAddress) {
        this.editRestaurantOutlet();
      } else if (
        this.updateAddrssStatus &&
        this.outletForm["controls"].latitude.value !== "" &&
        this.outletForm["controls"].latitude.value !== null &&
        this.outletForm["controls"].longitude.value !== "" &&
        this.outletForm["controls"].longitude.value !== null
      ) {
        this.editRestaurantOutlet();
      } else if (
        this.outletForm["controls"].address.value &&
        this.updateAddrssStatus == false
      ) {
        this.toastr.errorToastr(
          "Please select a valid address from suggestions."
        );
      }
    } else {
      this.outletForm.markAllAsTouched();
    }
  }

  editRestaurantOutlet() {
    if (this.outletForm.valid) {
      let data = this.prepareAndValidateData();
      if (data) {
        let param = `updateId=${this.item._id}`;
        data["updateId"] = this.item._id;
        this.api
          .putWithQuery(this.coreUrlList.outlet, param, data)
          .subscribe((res) => {
            if (res["response"]["success"]) {
              this.comm.successToast(res["response"]["message"]);
              this.dialog.close("yes");
            } else {
              this.dialog.close("no");
            }
          });
      }
    } else if (this.outletForm["controls"].address.value) {
      this.toastr.errorToastr(
        "Please select a valid address from suggestions."
      );
    }
  }

  handleAddress(address) {
    this.updateAddrssStatus = true;
    if (address.formatted_address) {
      this.outlet = {
        address: address.formatted_address,
        latitude: address.geometry.location.lat(),
        longitude: address.geometry.location.lng(),
      };
      this.outletForm.controls["address"].setValue(address.formatted_address);
      this.outletForm.controls["latitude"].setValue(
        address.geometry.location.lat()
      );
      this.outletForm.controls["longitude"].setValue(
        address.geometry.location.lng()
      );
    }
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
  }

  close() {
    this.dialog.close();
  }
}
