<h2 mat-dialog-title>Import Products</h2>

<mat-dialog-content>
  <form>
    <div class="usr_profile_inrmain">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="outer_uploadsshdf bigholderhor default_viewer">
              <p class="heading-setting-form">Select Excel Sheet</p>

              <div class="image_placeholder_showup_file">
                <div class="dftlp">
                  <figure>
                    <img
                      *ngIf="!fileName"
                      src="assets/img/upload_new.png"
                      alt="choose_img"
                    />
                  </figure>
                  <span>{{ fileName ? fileName : "upload File" }}</span>
                </div>

                <input
                  class="form-control"
                  type="file"
                  name="excelSheet"
                  (change)="fileHandler($event)"
                />
              </div>
            </div>
            <div *ngIf="errors && submitted" class="invalid-feedback">
              Please select Excel file.
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="col-12 btm-btn">
  <div class="botton_right_buttons">
    <mat-dialog-actions>
      <button class="btn btn-primary common-btn mr-2" (click)="onSubmit()">
        Import
      </button>
      <button class="btn btn-danger common-btn" (click)="close()">Close</button>
    </mat-dialog-actions>
  </div>
</div>
