import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "src/environments/environment";
import { CommonService } from "./common.service";
import { FirebaseService } from "./firebase.service";
initializeApp(environment.firebase);
const messaging = getMessaging();
@Injectable({
  providedIn: "root",
})
export class ServiceWorkerRegister {
  message: any = null;
  constructor(
    private fcmService: FirebaseService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
  publicApi(): void {
    let script: any;
    // eslint-disable-next-line prefer-const
    script = document.createElement("script");
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        const path = environment.path;
        const scope = environment.scope;
        navigator.serviceWorker
          .register(path, { scope })
          .then((registration) => {
            this.requestPermission(registration);
            this.listen();
          })
          .catch((error) => {
            console.log("Service Worker registration failed:", error);
          });
      });
    }
    document.getElementsByTagName("head")[0].appendChild(script);
  }
  requestPermission(sw: any): void {
    getToken(messaging, {
      vapidKey: environment.firebase.vapidKey,
      serviceWorkerRegistration: sw,
    })
      .then((currentToken: any) => {
        if (currentToken) {
          localStorage.setItem("fcmDeviceToken", currentToken);
          this.fcmService.fcmToken.next(currentToken);
        } else {
          alert(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        alert(err);
      });
  }
  listen(): void {
    onMessage(messaging, (payload: any) => {
      // Extract the URL from the push notification payload
      const url = payload.notification?.click_action || payload.data?.url;
      if (url) {
        // Redirect to the extracted URL
        window.location.href = url;
      }
      this.message = payload;
    });
  }
}
