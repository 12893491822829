import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { ImageFilterPipe } from "./pipes/image-filter.pipe";
import { appNoSpaceAtFirst } from "./directives/no-space.directive";
import { TextOnlyDirective } from "./directives/only-text.directive";
import { NumberDotOnlyDirective } from "./directives/number-dot-only.directive";

@NgModule({
  declarations: [
    NumberOnlyDirective,
    ImageFilterPipe,
    appNoSpaceAtFirst,
    TextOnlyDirective,
    NumberDotOnlyDirective,
  ],
  providers: [],
  imports: [CommonModule],
  exports: [
    NumberOnlyDirective,
    NumberDotOnlyDirective,
    ImageFilterPipe,
    appNoSpaceAtFirst,
    TextOnlyDirective,
  ],
})
export class CoreModule {}
