import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { CmsComponent } from "./shared/cms/cms.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "cms",
    component: CmsComponent,
  },
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/main/main.module").then((m) => m.MainModule),
  },

  {
    path: "auth",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
