import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ng6-toastr-notifications";
import { AngularWebStorageModule } from "angular-web-storage";
import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from "agm-direction";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DatePipe } from "@angular/common";
import { MaterialModule } from "./modules/material/material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { AuthGuard } from "./core/guards/auth.guard";
import { InitialService } from "./core/services/initial.service";
import { ForgotPasswordModalComponent } from "./modules/auth/forgot-password-modal/forgot-password-modal.component";
import { VerificationTypeComponent } from "./modules/auth/signup/verification-type/verification-type.component";
import { AcceptModalComponent } from "./modules/main/store/order/accept-modal/accept-modal.component";
import { StoreOutletmodalComponent } from "./modules/main/store/store/storeOutletModal/storeOutletModal.component";
import { coreUrlList } from "./core/urlList/coreUrlList";
import { SetInterceptorService } from "./core/interceptors/set-interceptor.service";
import { GetInterceptorService } from "./core/interceptors/get-interceptor.service";
import { ImportProductsModalComponent } from "./modules/main/add-properties/products/import-products-modal/import-products-modal.component";
import { SocketService } from "./core/services/socket.service";
import { CoreModule } from "./core/core.module";
import { ServiceWorkerRegister } from "./core/services/serviceWorker";
import { provideAuth } from "@angular/fire/auth";
import { getAuth } from "@firebase/auth";
import { provideFirebaseApp } from "@angular/fire/app";
import { initializeApp } from "firebase/app";
import { ServiceWorkerModule } from "@angular/service-worker";

export function startupServiceFactory(
  InitialService: InitialService
): Function {
  return () => InitialService.load();
}

export function setUpFirebase(InitialService: ServiceWorkerRegister): Function {
  return () => InitialService.publicApi();
}

export function countdownConfigFactory() {
  return { format: `dd:hh:mm:ss` };
}

@NgModule({
  declarations: [
    AppComponent,
    AcceptModalComponent,
    StoreOutletmodalComponent,
    ForgotPasswordModalComponent,
    VerificationTypeComponent,
    ImportProductsModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    AngularWebStorageModule,
    HttpClientModule,
    NgxSpinnerModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgmDirectionModule,
    GooglePlaceModule,
    CoreModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAZV-D16FyFCdPwaTBzUAjMgTS5s7QiLd0",
      libraries: ["geometry", "places"],
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerImmediately",
    }),

    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
  providers: [
    coreUrlList,
    InitialService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [InitialService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setUpFirebase,
      deps: [ServiceWorkerRegister],
      multi: true,
    },
    AuthGuard,
    SocketService,
    NgxSpinnerService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
