import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { encryptData } from "../helpers/encryptData.helper";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class SetInterceptorService implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    this.spinner.show();
    return next.handle(clonedReq);
  }

  handleRequest(req: HttpRequest<any>) {
    let fullUrl = window.location.href;
    let appId = environment.appId;

    let token = "";
    let geofenceId = "NA";

    // For dynamic build
    // appId = fullUrl.includes("localhost")
    //   ? appId
    //   : localStorage.appId
    //   ? localStorage.appId
    //   : "NA";
    // // For static build just comment Dynamic build code

    if (localStorage.getItem("merchantToken")) {
      token = localStorage.getItem("merchantToken");
    }

    if (localStorage.getItem("geofenceId")) {
      geofenceId = localStorage.getItem("geofenceId");
    }

    let authReq;
    let headers = {
      Authorization: token,
      geofenceid: geofenceId,
      appid: appId,
      date: moment().valueOf().toString(),
    };
    authReq = req.clone({
      headers: new HttpHeaders({
        hash: encryptData(JSON.stringify(headers), true),
        deviceType: "web",
      }),
    });

    if (req.method === "POST" || req.method === "PUT") {
      if (req.url.includes("upload/upload")) {
        authReq = authReq;
      } else {
        authReq["body"] = encryptData(JSON.stringify(authReq.body));
      }
    }

    return authReq;
  }
}
