<div class="outlet_cont">
  <div class="add_outlet">
    <h2 mat-dialog-title *ngIf="item">Edit Outlet</h2>
    <h2 mat-dialog-title *ngIf="storeId">Add Outlet</h2>
    <mat-dialog-content>
      <form [formGroup]="outletForm">
        <div class="usr_profile_inrmain">
          <div class="usr_profile_biodata">
            <label for="address">Address</label>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                name="name"
                formControlName="address"
                ngx-google-places-autocomplete
                #placesRef="ngx-places"
                (onAddressChange)="handleAddress($event)"
                placeholder="Address"
              />
              <div
                *ngIf="
                  outletForm.controls.address?.touched &&
                  outletForm.controls?.address?.errors
                "
                class="invalid-feedback"
              >
                <div *ngIf="outletForm.controls.address?.errors?.required">
                  Address is required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="latitude && longitude" style="min-height: 200px">
          <agm-map
            #gm
            [latitude]="latitude"
            [longitude]="longitude"
            (mapReady)="mapReady($event)"
            [mapTypeControl]="true"
            (mapClick)="mapClick($event)"
            [streetViewControl]="false"
            (mapReady)="mapReady($event)"
          >
            <agm-polygon
              [strokeColor]="'red'"
              [strokeWeight]="4"
              [paths]="polygonPts"
            ></agm-polygon>
            <!-- [fitBounds]="true" -->
            <agm-marker
              [latitude]="latitude"
              [longitude]="longitude"
              [markerDraggable]="true"
              (dragEnd)="markerDragEnds($event)"
              (mapReady)="onMapReady($event)"
            ></agm-marker>
          </agm-map>
        </div>

        <div class="row botm">
          <div class="col-md-6 high">
            <label for="types">Delivery Area Option*</label>
            <div class="input-group">
              <mat-select
                class="form-control"
                placeholder="Select"
                formControlName="deliveryAreaType"
                (selectionChange)="changeAreaType($event.value)"
              >
                <mat-option value="fixed_area">Fixed Delivery Area</mat-option>
                <mat-option value="geo_fence">Geo Fencing</mat-option>
              </mat-select>
              <div
                *ngIf="
                  outletForm.controls.deliveryAreaType.touched &&
                  outletForm.controls.deliveryAreaType.errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="outletForm.controls.deliveryAreaType.errors.required"
                >
                  Delivery Area Option is required
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="areaType == 'fixed_area'"
            class="form-group col-md-6 high add_resurant"
          >
            <label for="category">Delivery Area*</label>
            <div class="input-group">
              <input
                type="text"
                name=""
                id=""
                formControlName="deliveryArea"
                class="form-control"
                placeholder="Enter Radius Area (in Km)"
                appNoSpaceAtFirst
                appNumberOnly
              />

              <div
                *ngIf="
                  outletForm.controls.deliveryArea.touched &&
                  outletForm.controls.deliveryArea?.errors
                "
                class="invalid-feedback"
              >
                <div *ngIf="outletForm.controls.deliveryArea.errors.required">
                  Delivery Area is required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="areaType == 'geo_fence'" class="form-group add_resurant">
          <label for="category">Delivery Geofence*</label>
          <div class="btn-cancelled">
            <p>*Please select geofence inside the bounded area.</p>
          </div>
          <agm-map
            class="agm-maps"
            [zoom]="13"
            [latitude]="latitude"
            [longitude]="longitude"
            (mapReady)="onMapReady($event)"
            (overlaycomplete)="updatePointList($event)"
          >
            <agm-polygon
              [strokeColor]="'red'"
              [strokeWeight]="4"
              [paths]="polygonPts"
            ></agm-polygon>
            <agm-polygon
              [strokeColor]="'blue'"
              [strokeWeight]="2"
              [paths]="deliveryAreaGeofence"
            ></agm-polygon>
            <agm-marker
              [latitude]="latitude"
              [longitude]="longitude"
              [markerDraggable]="false"
            ></agm-marker>
          </agm-map>
        </div>
      </form>
    </mat-dialog-content>
    <div class="botton_right_buttons">
      <mat-dialog-actions>
        <button
          class="btn btn-primary common-btn"
          *ngIf="storeId"
          (click)="onSubmit()"
        >
          Add
        </button>
        <button
          class="btn btn-primary common-btn"
          *ngIf="item"
          (click)="onUpdate()"
        >
          Update
        </button>
        <button class="btn btn-danger common-btn" (click)="close()">
          Close
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
