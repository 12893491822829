import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-verification-type',
  templateUrl: './verification-type.component.html',
  styleUrls: ['./verification-type.component.scss']
})
export class VerificationTypeComponent implements OnInit {
  selectedType;
  constructor(
    public dialogRef: MatDialogRef<VerificationTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  continue() {
    this.dialogRef.close(this.selectedType);
  }
}
