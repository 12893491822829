import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  public fcmToken = new Subject<any>();
  constructor() {}
}
