<div class="forgot_wrap">
  <h2 mat-dialog-title>Forgot Password</h2>
  <mat-dialog-content>
    <form>
      <div class="form-group">
        <input
          type="email"
          class="form-control form-control-user"
          name="email"
          id="exampleInputEmail"
          [formControl]="email"
          aria-describedby="emailHelp"
          placeholder="Enter Email Id"
          required
        />
      </div>
    </form>
    <div *ngIf="isSubmitted && email.invalid" class="error-msg">
      <div *ngIf="email.errors.required">Email is required</div>
    </div>
  </mat-dialog-content>
  <div class="btn-right">
    <mat-dialog-actions>
      <button
        class="btn btn-primary common-btn forgot_btn mr-2"
        type="submit"
        (click)="onSubmit()"
      >
        Submit
      </button>
      <button class="btn btn-danger common-btn forgot_btn" (click)="close()">
        Cancel
      </button>
    </mat-dialog-actions>
  </div>
</div>
