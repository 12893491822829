import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { toArray } from "rxjs/operators";
import { HttpService } from "../services/http.service";
import { coreUrlList } from "../urlList/coreUrlList";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpService,
    private coreUrl: coreUrlList
  ) {
    // console.log(this.activatedRoute.snapshot.url);
    console.log(window.location.href, "jhggfdsfgj");
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (window.location.href.includes("/auth/merchantlogin")) {
      var arr = window.location.href.split("/");

      let arr1 = window.location.href.split("?")[1];
      if (arr1.includes("=")) {
        arr1 = arr1.split("=")[0];
      }
      console.log(arr[arr.length - 1]);
      localStorage.setItem(
        "merchantToken",
        arr[arr.length - 1].split("?")["0"]
      );
      this.http.getWithParams(this.coreUrl.storeById, arr1).subscribe((res) => {
        console.log(res);
        

        if (res["response"]["success"]) {
          console.log("response", res);
          localStorage.setItem("storeId", res["data"]["detail"]["_id"]);

          // localStorage.setItem("merchantToken", res["data"]["authToken"]);
          localStorage.setItem(
            "storeLogin",
            JSON.stringify(res["data"]["detail"])
          );
          localStorage.setItem("role", "merchant");
          this.router.navigate(["/dashboard"]);
        }
      });
    } else {
      return true;
    }

    if (state.url == "/auth/login") {
      console.log("1");

      if (
        localStorage.merchantToken == null ||
        localStorage.merchantToken == undefined ||
        localStorage.merchantToken == ""
      ) {
        return true;
      } else {
        console.log("2");
        this.router.navigate(["/dashboard"]);
        return false;
      }
    } else {
      if (
        localStorage.merchantToken == null ||
        localStorage.merchantToken == undefined ||
        localStorage.merchantToken == ""
      ) {
        console.log("3");
        this.router.navigate(["/auth/login"]);
      } else {
        return true;
      }
    }
  }
}
